/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ellipsisString,
formatDate
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { CBPPickupManifest } from "@/domain/entities/ShipmentBooking";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { createI18n } from "vue-i18n";

let doc: any;
let translate: any = () => { };

export const partnerTypes = (type: string) => {
    switch (type) {
        case "console":
            return "Consolidator";
        case "pos":
            return "POS";
        default:
            return "Sub-Consolidator";
    }
};

export const drawCellConditions = (cell: any) => {
    const head = cell.row?.section === "head";
    const body = cell.row?.section === "body";
    return [
        cell.column?.dataKey === "product" && head, //0
        cell.column?.dataKey === "product" && body, //1
        cell.column?.dataKey === "originCity" && head, //2
        cell.column?.dataKey === "originCity" && body,//3
        cell.column?.dataKey === "destCity" && head,//4
        cell.column?.dataKey === "destCity" && body //5
    ];
};

export const drawCellData = (cell: any) => {
    if (drawCellConditions(cell)[0]) {
        cell.row.cells[2].styles = {
            ...cell.row.cells[2].styles,
            cellPadding: {
                ...cell.row.cells[2].styles.cellPadding,
                left: 0.7
            }
        };
    }
    if (drawCellConditions(cell)[1]) {
        doc.setFillColor("#EBECF0");
        cell.row.cells[2].text
            .filter((item: any) => item !== "")
            .map((_: any, index: number) => {
                doc.roundedRect(
                    cell.row.cells[2].x + 0.7,
                    cell.row.cells[2].y +
                    cell.row.cells[2].contentHeight /
                    (1.6333 +
                        2.5 *
                        cell.row.cells[2].text.filter((item: any) => item !== "")
                            .length) +
                    (0.0083 + 5.675 * index),
                    cell.row.cells[2].contentWidth - 1,
                    4,
                    1,
                    1,
                    "F"
                );
            });
        doc.setFillColor("#fff");
    }
    if (drawCellConditions(cell)[2]) {
        cell.row.cells[6].styles = {
            ...cell.row.cells[6].styles,
            cellPadding: {
                ...cell.row.cells[6].styles.cellPadding,
                left: 0.7
            }
        };
    }
    if (drawCellConditions(cell)[3]) {
        doc.setFillColor("#EBECF0");
        cell.row.cells[6].text
            .filter((item: any) => item !== "")
            .map((_: any, index: number) => {
                doc.roundedRect(
                    cell.row.cells[6].x + 0.7,
                    cell.row.cells[6].y +
                    cell.row.cells[6].contentHeight /
                    (1.6333 +
                        2.5 *
                        cell.row.cells[6].text.filter((item: any) => item !== "")
                            .length) +
                    (0.0083 + 5.675 * index),
                    cell.row.cells[6].contentWidth - 1,
                    4,
                    1,
                    1,
                    "F"
                );
            });
        doc.setFillColor("#fff");
    }
    if (drawCellConditions(cell)[4]) {
        cell.row.cells[6].styles = {
            ...cell.row.cells[6].styles,
            cellPadding: {
                ...cell.row.cells[6].styles.cellPadding,
                left: 0.7
            }
        };
    }
    if (drawCellConditions(cell)[5]) {
        doc.setFillColor("#EBECF0");
        cell.row.cells[7].text
            .filter((item: any) => item !== "")
            .map((_: any, index: number) => {
                doc.roundedRect(
                    cell.row.cells[7].x + 0.7,
                    cell.row.cells[7].y +
                    cell.row.cells[7].contentHeight /
                    (1.6333 +
                        2.5 *
                        cell.row.cells[7].text.filter((item: any) => item !== "")
                            .length) +
                    (0.0083 + 5.675 * index),
                    cell.row.cells[7].contentWidth - 1,
                    4,
                    1,
                    1,
                    "F"
                );
            });
        doc.setFillColor("#fff");
    }
};

export const printmanifestPdf = (
    dataHead: any,
    data: any,
    logo: string
) => {
    doc = new jsPDF("p", "mm", "a4", true);
    // format Date
    const formattingDate = (date: any) => {
        return `${formatDate(date)}`;
    };

    const i18nInstance = createI18n({
        legacy: false,
        locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
        fallbackLocale: "id",
        globalInjection: false,
        messages: {
            en,
            id,
            my
        }
    });
    translate = i18nInstance.global.t;
    const pdfWidth = doc.internal.pageSize.width;
    const logoProperties = doc.getImageProperties(logo);
    const logoAspectRatio = logoProperties.height / logoProperties.width;

    const imageWidth = 40;
    const imageHeight = logoAspectRatio * 40;
    doc.addImage(
        logo,
        "PNG",
        pdfWidth - imageWidth - 9,
        11,
        imageWidth,
        imageHeight,
        "logo",
        "FAST",
        0
    );
    doc.setFont("Poppins", "bold");
    doc.setFontSize(16);
    doc.setTextColor("#4d4d4d");
    doc.text("Manifest", 10, 18);
    doc.setFillColor("#e0e0e0");
    doc.rect(10, 25, 191, 0.2, "F");
    doc.setFont("Poppins", "normal");
    doc.setFontSize(7);

    // left column
    doc.setTextColor("#4d4d4d");
    doc.setFillColor("#ebecf0");

    doc.text("Nama POS", 10, 35);
    doc.text(`: ${dataHead.posName}`, 33, 35);

    doc.text("Kota asal POS", 10, 42);
    doc.text(`: ${dataHead.posOrigin}`, 33, 42);

    doc.text("Tanggal Pick Up", 10, 49);
    doc.text(`: ${formattingDate(dataHead.pickupDate)}`, 33, 49);

    doc.text("Nama klien", 10, 56);
    doc.text(`: ${ellipsisString(dataHead.clientName, 70)}`, 33, 56);

    doc.text("Kota asal klien", 10, 63);
    doc.text(`: ${dataHead.clientOrigin}`, 33, 63);

    // // right column

    doc.text("Total STT", 115, 35);
    doc.text(`: ${dataHead.totalSTT}`, 140, 35);

    doc.text("Total Koli", 115, 42);
    doc.text(`: ${dataHead.totalPieces}`, 140, 42);

    doc.text("Total Berat Barang", 115, 49);
    doc.text(`: ${dataHead.totalGrossWeight} Kg`, 140, 49);

    let lastPage = 0;
    let lastY = 0;
    let lastHeight = 0;
    const dataLength = data.length;
    const columnsTable = () => [
        { header: "No", dataKey: "no" }, //0
        { header: `No. STT`, dataKey: "sttNo" }, //1 
        { header: translate("Jenis Pengiriman"), dataKey: "product" }, //2
        { header: translate("Tanggal"), dataKey: "date" },//3
        { header: translate("Total\nKoli"), dataKey: "koli" },//4
        { header: translate("Berat\nKotor"), dataKey: "grossWeight" },//5
        { header: "Origin", dataKey: "originCity" },//6
        { header: translate("Dest."), dataKey: "destCity" }//7
    ];
    const columnStyles = {
        0: { cellWidth: 10 },
        1: { cellWidth: 30 },
        2: { cellWidth: 20 },
        3: { cellWidth: 18 },
        4: { cellWidth: 14 },
        5: { cellWidth: 20 },
        6: { cellWidth: 16 },
        7: { cellWidth: 62 }
    };
    doc.autoTable({
        body: data,
        columns: columnsTable(),
        theme: "plain",
        startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 70 : 0,
        margin: { top: 7, left: 10, right: 10, bottom: 7 },
        headStyles: {
            font: "Poppins",
            fontStyle: "bold",
            fillColor: "#f5f6f7",
            textColor: "#1a1421",
            fontSize: 6,
            halign: "left",
            cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
        },
        bodyStyles: {
            font: "Poppins",
            fontStyle: "normal",
            fillColor: "#fff",
            textColor: "#1a1421",
            fontSize: 7,
            halign: "left",
            valign: "top",
            lineColor: "#e0e0e0",
            lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
            cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
            cellWidth: "auto"
        },
        columnStyles: columnStyles,
        willDrawCell: (cell: any) => {
            drawCellData(cell);
            lastPage = cell.pageNumber;
            lastY = cell.cursor.y;
            lastHeight = cell.row.height;
            if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
                cell.cell.contentHeight = 100;
                cell.row.height = 100;
            }
        }
    });

    doc.setProperties({
        title: "CBP Klien Manifest"
    });
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
};

export default printmanifestPdf;
